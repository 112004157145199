import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Home from './Home';
import Pricing from './Pricing';
import './assets/css/global.css';
import './assets/css/home.css';

function App() {
  useEffect(() => {
    const cursor = document.getElementById("custom-cursor");

    const handleMouseMove = (e) => {
      if (cursor) {
        cursor.style.left = `${e.clientX}px`;
        cursor.style.top = `${e.clientY}px`;
      }
    };

    document.body.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.body.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="App">
      <div id="custom-cursor"></div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import React from 'react';
import PriceTable from "./pricing/PriceTable";
import "./assets/css/pricing.css";

const Pricing = () => {
  return (
    <div>
      <PriceTable></PriceTable>
    </div>
  )
}

export default Pricing

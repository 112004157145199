// import React from 'react';
// import Logo from "./assets/images/Footer Logo Banner.png";
// import "./assets/css/footer.css"

// const Footer = () => {
//   return (
//     <>
//     <section id="cta" className="cta page-content h-100 pb-5" id="footer">
//       <div className="container cta-div py-md-4 py-3 px-md-5 px-4">
//         <div className="row cta-content p-md-5 p-4">
//           <div className="col-md-6 col-12 cta-text">
//             <h2 className='cta-title nimbus'>Lets Connect</h2>
//             <span className='d-flex align-items-center gap-3 mb-3'><i class="fa-solid fa-phone"></i><a href="tel:8422095295" className='text-decoration-none text-black'> +91 84220 95295</a></span>
//             <span className='d-flex align-items-center gap-3 mb-3'><i class="fa-solid fa-envelope"></i><a href="mailto:sneakpeak.digital@gmail.com" className='text-decoration-none text-black'> sneakpeak.digital@gmail.com</a></span>
//           </div>
//           <div className="col-md-6 col-12 cta-form">
//             <form action="post" className="form gap-5">
//               <div className="form-fields d-flex flex-column gap-3">
//                 <input type="text" className='form-control' placeholder='Name' />
//                 <input type="text" className='form-control' placeholder='Company Name' />
//                 <input type="email" className='form-control' placeholder='Email' />
//                 <input type="number" className='form-control' placeholder='Mobile Number' />
//                 <button type="submit" value="" className='btn btn-primary form-submit-btn custom-btn'>Submit</button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     </section>
//       <footer>
//         <div className="container d-flex align-items-center justify-content-center h-100">
//         <div className="brand-logo">
//               <a href="/">
//                 <img
//                   src={Logo}
//                   alt="Sneak Peak Media"
//                   className="footer-logo"
//                 />
//               </a>
//             </div>
//         </div>
//       </footer>
//     </>
//   )
// }

// export default Footer


import React from "react";
import Logo from "./assets/images/Footer Logo Banner.png";
import "./assets/css/footer.css";

const Footer = () => {
  const handleSubmit = (e) => {
    setTimeout(() => {
      e.target.reset(); // Reset form after submission
      alert("Form Submitted Successfully!");
    }, 1000);
  };

  return (
    <>
      <section id="cta" className="cta page-content h-100 pb-5">
        <div className="container cta-div py-md-4 py-3 px-md-5 px-4">
          <div className="row cta-content p-md-5 p-4">
            <div className="col-md-6 col-12 cta-text">
              <h2 className="cta-title nimbus">Let's Connect</h2>
              <span className="d-flex align-items-center gap-3 mb-3">
                <i className="fa-solid fa-phone"></i>
                <a href="tel:8422095295" className="text-decoration-none text-black">
                  +91 84220 95295
                </a>
              </span>
              <span className="d-flex align-items-center gap-3 mb-3">
                <i className="fa-solid fa-envelope"></i>
                <a href="mailto:sneakpeak.digital@gmail.com" className="text-decoration-none text-black">
                  sneakpeak.digital@gmail.com
                </a>
              </span>
            </div>
            <div className="col-md-6 col-12 cta-form">
              <form
                action="https://script.google.com/macros/s/AKfycbz8LS5tBpfxXVx20mFaikNoEfr-zwy3s7--poNoiNjTvmZ3b0mzcgHlY9nR2u7TOFDm/exec"
                method="POST"
                target="hidden_iframe"
                onSubmit={handleSubmit} // ✅ Use function reference here
              >
                <div className="form-fields d-flex flex-column gap-3">
                  <input type="text" className="form-control" placeholder="Name" name="name" required />
                  <input type="text" className="form-control" placeholder="Company Name" name="company" required />
                  <input type="email" className="form-control" placeholder="Email" name="email" required />
                  <input type="tel" className="form-control" placeholder="Mobile Number" name="mobile" required />
                  <button type="submit" className="btn btn-primary form-submit-btn custom-btn">
                    Submit
                  </button>
                </div>
              </form>
              <iframe name="hidden_iframe" style={{ display: "none" }}></iframe>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="container d-flex align-items-center justify-content-center h-100">
          <div className="brand-logo">
            <a href="/">
              <img src={Logo} alt="Sneak Peak Media" className="footer-logo" />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

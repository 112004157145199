import React from "react";

const PriceTable = () => {
  return (
    <>
      <section id="pricing">
        <div class="container ">
          <h2 class="section-title gradient text-center">Pricing</h2>
          <div class="row gap-3 justify-content-center mt-3">
            <div class="col pricing-table p-5">
              <h4 class="table-title fw-bold">Basic</h4>
              <span className="d-flex align-items-baseline"><h3 class="price fw-bolder">₹ 14,999</h3>/month</span>
              <p>Lorem ipsum dolor sit amet.</p>
              <ul class="features list-style-none pt-2 ps-0">
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
              </ul>
              <button class="btn custom-btn custom-black-btn"><a href="#cta">Get Started</a></button>
            </div>
            <div class="col pricing-table p-5">
              <h4 class="table-title fw-bold">Advanced</h4>
              <span className="d-flex align-items-baseline"><h3 class="price fw-bolder">₹ 19,999</h3>/month</span>
              <p>Lorem ipsum dolor sit amet.</p>
              <ul class="features list-style-none pt-2 ps-0">
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
              </ul>
              <button class="btn custom-btn custom-black-btn"> <a href="#cta">Get Started</a></button>
            </div>
            <div class="col pricing-table p-5">
              <h4 class="table-title fw-bold">Pro</h4>
              <span className="d-flex align-items-baseline"><h3 class="price fw-bolder">₹ 24,999</h3>/month</span>
              <p>Lorem ipsum dolor sit amet.</p>
              <ul class="features list-style-none pt-2 ps-0">
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
                <li class="list-group-item d-flex gap-3">
                  <span class="icon">
                    <i class="fa-solid fa-circle-check"></i>
                  </span>
                  <p class="feature-title">Audit</p>
                </li>
              </ul>
              <button class="btn custom-btn custom-black-btn"><a href="#cta">Get Started</a></button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PriceTable;
